import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useRouter } from "./router/router";
import { GlobalStyles } from "./global";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./theme";
import "./App.css";
import ThemeToggle from "./components/themeToggle/ThemeToggle";

function App(props) {
  const routing = useRouter(props);
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"
  );

  useEffect(() => {
    if (localStorage.getItem("theme")) {
      localStorage.setItem("theme", theme);
    }
  }, [theme]);

  useEffect(() => {
    if (localStorage.getItem("theme")) {
      setTheme(localStorage.getItem("theme"));
    } else {
      localStorage.setItem("theme", theme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  return (
    <>
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <GlobalStyles />
        <Router>
          <ThemeToggle theme={theme} toggleTheme={toggleTheme} />

          {routing}
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
