import React from "react";
import styles from "./themeToggle.module.css";
import toggle from "../../assets/themeToggle/toggle.svg";
import { useHistory } from "react-router-dom";

const ThemeToggle = ({ toggleTheme, theme }) => {
  const history = useHistory();
  const refresh = async() => {
    toggleTheme();
    setTimeout(() => history.go(), 180);
  };
  return (
    <div
      onClick={async () => refresh()}
      className={
        theme === "light" ? styles.toggleWrapperLight : styles.toggleWrapper
      }
    >
      <img alt="toggle" className={styles.toggle} src={toggle} />
    </div>
  );
};

export default ThemeToggle;
