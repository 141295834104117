export const lightTheme = {
    body: '#ddd',
    text: '#000',
    btnActive: '#fff',
    btn: '#ddddddb6',
    header: '#aaaaaad2',
    mobnav: '#ddddddf1',
    backdrop: '#dddddd59',
    menu: '#10111A59',
    mainColor: '#5638C0',
    yellow: '#faff00',
    yellowLight: '#7D6BC4',
    violetLight: '#7D6BC4',
    hover: '#2B2744'
  }
  
  export const darkTheme = {
    body: '#10111A',
    text: '#fff',
    btnActive: '#F7F155',
    btn: '#10111Ab6',
    header: '#10111Ad2',
    mobnav: '#101119f1',
    backdrop: '#10111959',
    menu: '#ddddddb6',
    mainColor: '#F9F018',
    yellow: '#F9F018',
    yellowLight: '#ecd95f',
    violetLight: '#b4a6e9',
    hover: '#fff'
  }