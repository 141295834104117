import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
html {
    --color-text: ${({ theme }) => theme.text};
    --color-theme: ${({ theme }) => theme.body};
    --color-blue: #5638C0;
    --color-violetLight: ${({ theme }) => theme.violetLight};
    --color-yellow: ${({ theme }) => theme.yellow};
    --color-yellowLight: ${({ theme }) => theme.yellowLight};
    --color-mainColor: ${({ theme }) => theme.mainColor};
    --color-header: rgba(180, 142, 124, 0.9);
    --color-btnActive: ${({ theme }) => theme.btnActive};
    --color-btn: ${({ theme }) => theme.btn};
    --color-header: ${({ theme }) => theme.header};
    --color-mobnav: ${({ theme }) => theme.mobnav};
    --color-backdrop: ${({ theme }) => theme.backdrop};
    --color-menu: ${({ theme }) => theme.menu};
    --color-hover: ${({ theme }) => theme.hover};
  }
  *,
  *::after,
  *::before {
    box-sizing: border-box;
    transition: all 0.2s ease-in;
  }
  * {
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 400;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    scroll-behavior: smooth;
  }
  p,
  span,
  h1,
  h2,
  h3,
  h4 {
    cursor: default;
  }
  
  button {
    outline: none;
    border: none;
    cursor: pointer;
  }
  input,
  textarea {
    border: none;
    background-color: transparent;
  }
  input:focus,
  textarea:focus {
    outline: none;
  }
  ul {
    padding: 0;
  }
  a {
    color: var(--color-text);
    display: block;
    cursor: pointer;
  }
  img {
    display: block;
  }
  
  body::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  body {
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; 
    color: var(--color-text);
    background-color: var(--color-theme);
    margin: 0;
    padding: 0;
    font-family: 'Grotesk', sans-serif;
    transition: all 0.3s easy-in;
  }
  .text{
    font-size: 17px;
    color: var(--color-text);
  }
  .section{
    padding-top: 80px;
    padding-bottom: 20px;
    width: 100vw;
  }
  .container{
    width: 80%;
    margin: 0 auto;
  }
  .sectionTitle{
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin: 30px 0;
    color: var(--color-text);
    text-indent: 54px;
    position: relative;
    font-family: 'Grotesk Cn', sans-serif;
  }
  .sectionTitle:before{
    position: absolute;
    content: "";
    width: 26px;
    height: 4px;
    background-color: var(--color-yellow);
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
  }
  #first{
    height: 550px;
  }
  #services{
   padding-top:100px;
  }
  #about{
    padding-top: 0px;
    padding-bottom: 0px;
    overflowx: hidden;
  }
  @media (min-width: 768px) {
    .section{
      height: 100%;
    }
    .sectionTitle{
      font-size: 20px;
      line-height: 30px;
      text-indent: 60px;
    }
    .sectionTitle:before{
      width: 30px;
      height: 6px;
    }
    .container{
      width: 530px;
      margin: 0 auto;
    }
    #first{
      height: 550px;
    }
    #services{
      padding-top: 0px;
      padding-bottom: 0px;
    }
    #footer{
      scroll-snap-align: start;
    }
  }
  @media (min-width: 1200px) {
    
    #first{
      height: 100vh;
    }
    .sectionTitle{
      text-indent: 114px;
    }
    .section{
      height: 100vh;
      display: flex;
      align-items: center;
    }    
    
    #services{
      padding-top: 0;
    }

    #about{
      margin-bottom: 0;
    }

    .sectionTitle:before{
      width: 40px;
    }
    .container{
      width: 70vw;
      max-width: 70vw;
    }
    @media (min-width: 1500px) {
      .container{
        width: 60vw;
        max-width: 60vw;
      }
    }
  }
  `;
