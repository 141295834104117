import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Loader from "../components/loader/Loader";

const LazyHome = lazy(
  () =>
    import(
      "../components/pages/homePage/HomePage"
    ) /* webpackChunkName: 'Home' */
);

const LazyServices = lazy(
  () =>
    import(
      "../components/newServices/NewServices"
    ) /* webpackChunkName: 'Services' */
);

const Lazy404 = lazy(
  () =>
    import(
      "../components/pages/notFoundPage/NotFoundPage"
    ) /* webpackChunkName: '404' */
);

export const useRouter = () => {
  return (
    <Suspense
      fallback={
        <>
          <Loader />
        </>
      }
    >
      <Switch>
        <Route exact path="/" component={LazyHome} />
        {/* <Route path="/services#iam" component={LazyServices} />
        <Route path="/services#support" component={LazyServices} /> 
        <Route path="/services#csis" component={LazyServices} />
        <Route path="/services#audit" component={LazyServices} /> */}
        {/* <Route path="/services" component={LazyServices} /> */}
        <Route
          path="/services"
          component={LazyServices}
          render={(props) => {
            if (
              props.location.hash === "#iam" ||
              props.location.hash === "#support" ||
              props.location.hash === "#csis" ||
              props.location.hash === "#audit"
            ) {
              return <LazyServices />;
            } else return <Lazy404 />;
          }}
        />
        <Route path="*" component={Lazy404} />
      </Switch>
    </Suspense>
  );
};
